import { io } from "socket.io-client";

const socket = io(process.env.VUE_APP_AI_URL + '/chatbot-ai-preview',
  {
    autoConnect: true,
    transports: ['websocket']
  }
);

export default socket;
