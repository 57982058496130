<template>
  <div>
    <vs-card id="div-with-loading" class="vs-con-loading__container">
      <div slot="header">
        <h3>
          {{ $t("ChatbotBehavior") }}
        </h3>
      </div>
      <vs-row class="p-3">
        <vs-col vs-lg="7" vs-sm="8">
          <div class="flex flex-wrap mb-3">
            <vs-select
              v-model="chatbotGoal"
              width="100%"
              label="Escolha um objetivo"
            >
              <vs-select-item
                v-for="(item, index) in chatbotGoalOptions"
                :key="index"
                :value="item._id"
                :text="item.name"
              />
            </vs-select>
          </div>
          <div class="flex flex-wrap mb-12">
            <label class="vs-input--label"
              >Configuração avançadas de comportamento</label
            >
            <vs-textarea
              v-model="promptText"
              rows="21"
              placeholder="Aqui haverá um prompt padrão de acordo com o objetivo do usuário"
            />
            <p><span><router-link :to="{ name: 'faq' }">Saiba mais como configurar o prompt</router-link>.</span></p>
          </div>
          <div class="flex flex-wrap mb-12">
          <vs-row v-if="$store.state.user.userRole === 'master'">
            <vs-col vs-w="6" vs-sm="12">
              <vs-table
                :key="intentionTableKey"
                hoverFlat
                stripe
                :data="intentions"
                class="mb-1"
                :noDataText="$t('EmptyTable')"
              >
                <template slot="thead">
                  <vs-th>
                    {{ $t("Intentions") }}
                    <vs-button
                      radius
                      size="small"
                      class="ml-2"
                      icon-pack="feather"
                      icon="icon-plus"
                      @click="openPopupNewIntention()"
                    >
                    </vs-button>
                  </vs-th>
                  <vs-th>{{ $t("Actions") }}</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="indextr + 1">
                      {{ tr }}
                    </vs-td>
                    <vs-td :data="indextr + 1">
                      <div v-if="tr !== 'Atendimento IA'">
                        <vs-td>
                          <div class="flex justify-end">
                            <vs-tooltip
                              class="self-center mr-3 mt-2"
                              text="Editar"
                            >
                              <vs-button
                                type="flat"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click="openPopupEditIntention(tr, indextr)"
                              />
                            </vs-tooltip>
                            <vs-tooltip
                              class="self-center mr-3 mt-2"
                              text="Remover"
                            >
                              <vs-button
                                type="flat"
                                color="danger"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click="openPopupDeleteIntention(tr, indextr)"
                              />
                            </vs-tooltip>
                          </div>
                        </vs-td>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </vs-col>
            
            <div class="w-5"></div>
            <vs-col vs-w="5" vs-sm="12">
              <vs-table
                :key="entityTableKey"
                stripe
                hoverFlat
                :data="keyEntities"
                :noDataText="$t('EmptyTable')"
              >
                <template slot="thead">
                  <vs-th>
                    {{ $t("KeyEntities") }}
                    <vs-button
                      radius
                      size="small"
                      class="ml-2"
                      icon-pack="feather"
                      icon="icon-plus"
                      @click="openPopupNewEntity"
                    >
                    </vs-button>
                  </vs-th>

                  <vs-th>{{ $t("Actions") }}</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="indextr + 1">
                      {{ data[indextr] }}
                    </vs-td>
                    <vs-td :data="indextr + 1">
                      <div v-if="tr !== 'Atendimento IA'">
                        <vs-td>
                          <div class="flex justify-end">
                            <vs-tooltip
                              class="self-center mr-3 mt-2"
                              text="Editar"
                            >
                              <vs-button
                                type="flat"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click="openPopupEditEntity(tr, indextr)"
                              />
                            </vs-tooltip>
                            <vs-tooltip
                              class="self-center mr-3 mt-2"
                              text="Remover"
                            >
                              <vs-button
                                type="flat"
                                color="danger"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click="openPopupDeleteEntity(tr, indextr)"
                              />
                            </vs-tooltip>
                          </div>
                        </vs-td>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </vs-col>
          </vs-row>
        </div>

          <div
            v-if="$store.state.user.userRole === 'master'"
            class="flex justify-between items-center flex-wrap mb-5"
          >
            <div class="mb-10">
              <vs-select v-model="gptChatModel" label="Modelo ChatGPT">
                <vs-select-item
                  v-for="(item, index) in gptChatModelOptions"
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                />
              </vs-select>
            </div>
            <div class="mb-5">
              <ul class="flex">
                <li class="flex items-center">
                  <label class="mr-3 text-base"
                    >Transferência para humano</label
                  >
                  <vs-switch
                    v-model="humanTransfer"
                    vs-icon-off="close"
                    vs-icon-on="done"
                  />
                  <vs-button
                    v-if="this.currentChatbot"
                    type="flat"
                    color="dark"
                    icon-pack="feather"
                    icon="icon-external-link"
                    class="mt-1 ml-1"
                    @click="handleFunctionsPopup('humanTransfer')"
                  />
                </li>
                <li class="flex ml-12 items-center">
                  <label class="mr-3 text-base">Encerrar conversa</label>
                  <vs-switch
                    v-model="closeConversation"
                    vs-icon-off="close"
                    vs-icon-on="done"
                  />
                  <vs-button
                    v-if="this.currentChatbot"
                    type="flat"
                    color="dark"
                    icon-pack="feather"
                    icon="icon-external-link"
                    class="mt-1 ml-1"
                    @click="handleFunctionsPopup('closeConversation')"
                  />
                </li>
                <li class="flex ml-12 items-center">
                  <label class="mr-3 text-base">Adicionar Tag</label>
                  <vs-switch
                    v-model="addTag"
                    vs-icon-off="close"
                    vs-icon-on="done"
                  />
                  <vs-button
                    v-if="this.currentChatbot"
                    type="flat"
                    color="dark"
                    icon-pack="feather"
                    icon="icon-external-link"
                    class="mt-1 ml-1"
                    @click="handleFunctionsPopup('addTag')"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-wrap mb-8">
            <vs-table
              stripe
              :hoverFlat="true"
              :data="rowData"
              class="w-full"
              pagination
              max-items="10"
              :noDataText="$t('EmptyTable')"
            >
              <template v-slot:header>
                <h3>Base de conhecimento</h3>
              </template>
              <template v-slot:thead>
                <vs-th>Conteúdo</vs-th>
                <vs-th>Tipo</vs-th>
                <vs-th>Status</vs-th>
                <vs-th class="flex justify-center">Ações</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="tr.name">
                    {{ tr.name }}
                  </vs-td>
                  <vs-td :data="tr.type">
                    {{ tr.type === "File" ? "Arquivo" : "Site" }}
                  </vs-td>
                  <vs-td :data="tr.status">
                    <vs-chip
                      transparent
                      :color="
                        tr.status === 'Error'
                          ? 'danger'
                          : tr.status === 'Treinando'
                          ? 'primary'
                          : tr.status === 'Treinado'
                          ? 'success'
                          : 'dark'
                      "
                    >
                      {{
                        tr.status === "Error"
                          ? "ERRO"
                          : tr.status === "Treinando"
                          ? "TREINANDO"
                          : tr.status === "Treinado"
                          ? "TREINADO"
                          : "NÃO TREINADO"
                      }}
                    </vs-chip>
                  </vs-td>
                  <vs-td>
                    <div class="flex justify-end">
                      <vs-tooltip
                        v-if="tr.type === 'Url'"
                        class="self-center mr-3 mt-2"
                        :text="`Atualizado em ${formatDate(
                          tr.updatedAt
                        )} (Atualização diária)`"
                      >
                        <vs-icon
                          icon-pack="feather"
                          icon="icon-info"
                          class="text-grey"
                        >
                        </vs-icon>
                      </vs-tooltip>
                      <vs-button
                        v-if="tr.type !== 'Url'"
                        type="flat"
                        color="dark"
                        icon-pack="feather"
                        icon="icon-download"
                        class="mt-1"
                        target
                        :href="tr.url"
                      />
                      <vs-button
                        v-else
                        type="flat"
                        color="dark"
                        icon-pack="feather"
                        icon="icon-external-link"
                        class="mt-1"
                        target
                        :href="tr.name"
                      />
                      <vs-button
                        type="flat"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-trash"
                        class="mt-1"
                        @click="openPopupDeleteFile(tr)"
                      />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <div class="flex flex-wrap mb-3">
            <vs-button
              icon-pack="feather"
              icon="icon-upload"
              icon-after
              class="w-full"
              @click="popupUpload = true"
            >
              Adicionar Conteúdo
            </vs-button>
          </div>
          <div class="flex flex-wrap mb-3">
            <vs-button
              color="success"
              icon-pack="feather"
              icon="icon-save"
              icon-after
              class="w-full"
              @click="saveGoalToChat()"
            >
              {{ $t("Save") }}
            </vs-button>
          </div>
        </vs-col>
        <vs-col
          v-if="currentChatbot !== null"
          vs-lg="5"
          vs-sm="4"
          vs-type="flex"
          vs-justify="center"
        >
          <ChatAIPreview :chatbot="currentChatbot" v-if="currentChatbot" />
        </vs-col>
      </vs-row>
    </vs-card>

    <vs-popup
      :title="`${editIntentionIndex >= 0 ? 'Editar' : 'Adicionar'} Intenção`"
      :active.sync="newIntentionPopup"
    >
      <div class="flex flex-wrap">
        <div class="w-full">
          <div class="mt-3 ml-1">
            <vs-input
              autofocus
              v-model="newIntention"
              name="intention"
              type="text"
              class="w-full input--lowercase my-3"
              :label="$t('Intention')"
            />
          </div>
          <vs-divider />
          <vs-button
            :disabled="newIntention.trim().length === 0"
            @click="addOrUpdateIntention"
            size="medium"
            class="mb-4 md:mb-0"
          >
            {{ $t(editIntentionIndex >= 0 ? "Edit" : "Add") }}
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup title="Deletar Intenção" :active.sync="popupDeleteIntention">
      <vs-row>
        <vs-col>
          <h5 class="text-center">
            Tem certeza que deseja remover a intenção - {{ newIntention }}
          </h5>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-justify="end">
          <vs-button
            color="danger"
            icon="icon-trash"
            icon-pack="feather"
            icon-after
            @click="deleteIntention(newIntention)"
            class="px-2"
          >
            {{ $t("Remove") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup
      :title="`${editEntityIndex >= 0 ? 'Editar' : 'Adicionar'} Entidade`"
      :active.sync="newEntityPopup"
    >
      <div class="flex flex-wrap">
        <div class="w-full">
          <div class="mt-3 ml-1">
            <vs-input
              autofocus
              v-model="newEntity"
              name="Entity"
              type="text"
              class="w-full input--lowercase my-3"
              :label="$t('Entity')"
            />
          </div>
          <vs-divider />
          <vs-button
            :disabled="newEntity.trim().length === 0"
            @click="addOrUpdateEntity"
            size="medium"
            class="mb-4 md:mb-0"
          >
            {{ $t(editEntityIndex >= 0 ? "Edit" : "Add") }}
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup title="Deletar Entidade" :active.sync="popupDeleteEntity">
      <vs-row>
        <vs-col>
          <h5 class="text-center">
            Tem certeza que deseja remover a intenção - {{ newEntity }}
          </h5>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-justify="end">
          <vs-button
            color="danger"
            icon="icon-trash"
            icon-pack="feather"
            icon-after
            @click="deleteEntity(newEntity)"
            class="px-2"
          >
            {{ $t("Remove") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup :title="popupEditFunctionTitle" :active.sync="popupEditFunction">
      <vs-row>
        <vs-textarea
          :spellcheck="false"
          v-model="popupEditFunctionValue"
          rows="15"
          class="w-full mt-2 font-bold"
        />
        <vs-button
          class="w-full"
          color="success"
          :disabled="popupEditFunctionValue.trim() === ''"
          @click="handleSaveFunction"
        >
          {{ $t("Save") }}
        </vs-button>
      </vs-row>
    </vs-popup>

    <vs-popup title="Adicionar conteúdo" :active.sync="popupUpload">
      <vs-row vs-type="flex" vs-justify="space-around">
        <vs-col vs-w="5">
          <div
            class="border-solid border rounded-md p-4 flex items-center h-16 cursor-pointer hover:border-primary"
            @click="typeUpload = 'file'"
            :class="[typeUpload === 'file' ? 'border-primary' : 'border-grey']"
          >
            <vs-radio v-model="typeUpload" vs-name="radios1" vs-value="file"
              >Arquivo</vs-radio
            >
          </div>
        </vs-col>
        <vs-col vs-w="5">
          <div
            class="border-solid border rounded-md p-4 flex items-center h-16 cursor-pointer hover:border-primary"
            @click="typeUpload = 'site'"
            :class="[typeUpload === 'site' ? 'border-primary' : 'border-grey']"
          >
            <vs-radio v-model="typeUpload" vs-name="radios1" vs-value="site"
              >Site</vs-radio
            >
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="mt-5" v-if="typeUpload === 'file'">
          <p class="text-base text-center">
            Formatos suportados: pdf, txt, doc, docx.<br />
            Limite de 100 arquivos com 50mb cada
          </p>
        </vs-col>
        <vs-col class="mt-5" v-if="typeUpload === 'file'">
          <custom-tag
            v-for="(item, index) in filesToUpload"
            :key="index"
            :label="item.name || item"
            color="grey"
            remove
            @remove="removeFile(index)"
            :class="{ 'mt-3': index > 0 }"
            icon="FileTextIcon"
          />
        </vs-col>
        <vs-col class="mt-5" v-else>
          <custom-tag
            v-for="(item, index) in sitesToUpload"
            :key="index"
            :label="item.name || item"
            color="grey"
            remove
            @remove="removeSite(index)"
            :class="{ 'mt-3': index > 0 }"
            icon="LinkIcon"
          />
        </vs-col>
        <vs-col class="mt-3" vs-w="12" v-if="typeUpload === 'file'">
          <input
            type="file"
            class="hidden"
            ref="fileUpload"
            accept=".pdf, .txt, .doc, .docx"
            multiple
            v-on:input="handleFilesBeforeUpload"
          />
          <vs-button
            icon-pack="feather"
            icon="icon-upload"
            icon-after
            :disabled="disableButtons"
            @click="$refs.fileUpload.click()"
            class="w-full"
          >
            Adicionar arquivos
          </vs-button>
        </vs-col>
        <vs-col class="mt-5" vs-w="9" v-else>
          <vs-input
            v-model="site"
            placeholder="Inserir URL do site"
            type="url"
            icon-pack="feather"
            icon="icon-globe"
            class="w-12/12"
            :danger="!isUrlValid && site.length > 0"
            danger-text="Url inválida."
          />
        </vs-col>
        <vs-col class="mt-5" vs-w="3" v-if="typeUpload === 'site'">
          <vs-button
            icon-pack="feather"
            icon="icon-plus"
            icon-after
            @click="
              sitesToUpload.push(site);
              site = '';
            "
            :disabled="
              site.length === 0 || !isUrlValid || sitesToUpload.length >= 100
            "
          >
            Adicionar
          </vs-button>
        </vs-col>
        <vs-col class="mt-5" vs-w="12">
          <vs-button
            icon-pack="feather"
            icon="icon-save"
            icon-after
            class="w-full"
            color="success"
            @click="handleUpload()"
            :disabled="typeUpload === 'site' && sitesToUpload.length === 0"
          >
            {{ $t("Save") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup title="Deletar conteúdo" :active.sync="popupDeleteFile">
      <vs-row>
        <vs-col>
          <h5 class="text-center">
            Tem certeza que deseja remover o conteúdo - {{ fileToDelete.name }}
          </h5>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-justify="end">
          <vs-button
            color="danger"
            icon="icon-trash"
            icon-pack="feather"
            icon-after
            @click="deleteFile(fileToDelete._id)"
            class="px-2"
          >
            {{ $t("Remove") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import CustomTag from "@/components/CustomTag.vue";
import axios from "../../../http/axios";
import moment from "moment";
import ChatAIPreview from "@/components/duotalk-ia-settings/ChatAIPreview.vue";

export default {
  name: "ChatbotAISettings",
  components: { CustomTag, ChatAIPreview },
  data() {
    return {
      intentionTableKey: 1000,
      entityTableKey: 200,
      popupDeleteIntention: false,
      popupDeleteEntity: false,
      editIntentionIndex: -1,
      editEntityIndex: -1,
      intentions: [],
      keyEntities: [],
      newIntention: "",
      newEntity: "",
      newIntentionPopup: false,
      newEntityPopup: false,
      iframeKey: 1,
      popupEditFunction: false,
      popupEditFunctionType: null,
      popupEditFunctionTitle: "",
      popupEditFunctionValue: "",
      aiAddTagDefault: `{
  "description": "Determine alguma tag para o usuário quando pegar alguma informação importante como empresa, cargo, email, telefone",
  "parameters": {
    "type": "object",
    "properties": {
      "tag": {
        "type": "string",
        "description": "exemplo de tag 'empresa: valor', 'email: valor' sempre com chave: valor"
      }
    },
    "required": [
      "tag"
    ]
  }
}`,
      aiFunctionCloseConversationDefault: `{
  "description": "Execute essa função quando o usuário nao quiser mais conversar",
  "parameters": {
    "type": "object",
    "properties": {},
    "required": []
  }
}`,
      aiFunctionForwardHumanDefault: `{
  "description": "Se a pessoa quiser mais informações ou informações que voce nao possua determine o setor para a pessoa: -vendas -suporte",
  "parameters": {
      "type": "object",
      "properties": {
          "setor": {
              "type": "string",
              "description": "vendas ou suporte"
          }
      },
      "required": ["setor"]
  }
}`,
      currentChatbot: null,
      chatbotGoal: "",
      chatbotGoalOptions: [],
      goal: null,
      promptText: "",
      popupUpload: false,
      typeUpload: "file",
      filesToUpload: [],
      popupDeleteFile: false,
      fileToDelete: {},
      site: "",
      sitesToUpload: [],
      addTag: false,
      humanTransfer: false,
      closeConversation: false,
      gptChatModel: "gpt-4o-mini",
      gptChatModelOptions: [
        { value: "gpt-3.5-turbo", text: "GPT-3.5 Turbo" },
        { value: "gpt-4o", text: "GPT-4o" },
        { value: "gpt-4o-mini", text: "GPT-4o Mini" },
      ],
    };
  },
  async mounted() {
    await this.fetchChatbot();
    await this.getGoals();
    await this.listFiles();

    if (this.currentChatbot.id && this.currentChatbot.objective !== undefined) {
      this.chatbotGoal = this.currentChatbot.objective;
    } else {
      this.chatbotGoal = this.chatbotGoalOptions[0]._id;
      await this.getGoal(this.chatbotGoal);
    }

    this.humanTransfer = this.currentChatbot.aiForwardConversationFn;
    this.closeConversation = this.currentChatbot.aiCloseConversationFn;
    this.addTag = this.currentChatbot.aiAddTagFn;
    this.gptChatModel = this.currentChatbot.aiOpenaiModel;
  },
  computed: {
    rowData() {
      return this.$store.state.acc.aiFiles;
    },
    disableButtons() {
      return this.totalFiles >= this.filesLimit;
    },
    totalFiles() {
      return this.rowData.length + this.filesToUpload.length;
    },
    isUrlValid() {
      if (!this.site) return false;

      const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator

      return urlPattern.test(this.site);
    },
  },
  methods: {
    addOrUpdateIntention() {
      if (!this.newIntention) return;

      if (
        this.editIntentionIndex >= 0 &&
        this.intentions[this.editIntentionIndex].toLowerCase() ===
          this.newIntention.toLowerCase()
      ) {
        this.intentions[this.editIntentionIndex] = this.newIntention;
        this.newIntentionPopup = false;
        return;
      }

      if (
        this.intentions.find(
          (i) => i.toLowerCase() === this.newIntention.toLowerCase()
        )
      ) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: "Intenção já cadastrada",
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "warning",
          position: "top-right",
        });
        return;
      }

      if (this.editIntentionIndex >= 0) {
        this.intentions[this.editIntentionIndex] = this.newIntention;
      } else {
        this.intentions.push(this.newIntention);
      }

      this.intentionTableKey += 1;
      this.newIntentionPopup = false;
    },
    addOrUpdateEntity() {
      if (!this.newEntity) return;

      if (
        this.editEntityIndex >= 0 &&
        this.keyEntities[this.editEntityIndex].toLowerCase() ===
          this.newEntity.toLowerCase()
      ) {
        this.keyEntities[this.editEntityIndex] = this.newEntity;
        this.newEntityPopup = false;
        return;
      }

      if (
        this.keyEntities.find(
          (i) => i.toLowerCase() === this.newEntity.toLowerCase()
        )
      ) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: "Entidade já cadastrada",
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "warning",
          position: "top-right",
        });
        return;
      }

      if (this.editEntityIndex >= 0) {
        this.keyEntities[this.editEntityIndex] = this.newEntity;
      } else {
        this.keyEntities.push(this.newEntity);
      }

      this.entityTableKey += 1;
      this.newEntityPopup = false;
    },
    deleteIntention(intention) {
      this.intentions = this.intentions.filter((i) => i !== intention);
      this.popupDeleteIntention = false;
    },
    deleteEntity(entity) {
      this.keyEntities = this.keyEntities.filter((i) => i !== entity);
      this.popupDeleteEntity = false;
    },
    openPopupNewIntention() {
      this.newIntention = "";
      this.editIntentionIndex = -1;
      this.newIntentionPopup = true;
    },
    openPopupNewEntity() {
      this.newEntity = "";
      this.editEntityIndex = -1;
      this.newEntityPopup = true;
    },
    openPopupEditIntention(intention, index) {
      this.editIntentionIndex = index;
      this.newIntention = intention;
      this.newIntentionPopup = true;
    },
    openPopupEditEntity(entity, index) {
      this.editEntityIndex = index;
      this.newEntity = entity;
      this.newEntityPopup = true;
    },
    openPopupDeleteIntention(intention) {
      this.newIntention = intention;
      this.popupDeleteIntention = true;
    },
    openPopupDeleteEntity(entity) {
      this.newEntity = entity;
      this.popupDeleteEntity = true;
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    handleFunctionsPopup(type) {
      this.popupEditFunctionType = type;

      switch (type) {
        case "humanTransfer":
          this.popupEditFunctionTitle = "Transferência para humano";
          this.popupEditFunctionValue =
            this.currentChatbot.aiForwardConversationFnValue ||
            this.aiFunctionForwardHumanDefault;
          break;
        case "closeConversation":
          this.popupEditFunctionTitle = "Encerrar conversa";
          this.popupEditFunctionValue =
            this.currentChatbot.aiCloseConversationFnValue ||
            this.aiFunctionCloseConversationDefault;
          break;
        case "addTag":
          this.popupEditFunctionTitle = "Adicionar tag";
          this.popupEditFunctionValue =
            this.currentChatbot.aiAddTagFnValue || this.aiAddTagDefault;
          break;
      }

      this.popupEditFunction = true;
    },
    async handleSaveFunction() {
      const data = {
        aiCloseConversationFn: this.closeConversation,
        aiForwardConversationFn: this.humanTransfer,
        aiAddTagFn: this.addTag,
      };

      switch (this.popupEditFunctionType) {
        case "humanTransfer":
          data.aiForwardConversationFnValue = this.popupEditFunctionValue;
          break;
        case "closeConversation":
          data.aiCloseConversationFnValue = this.popupEditFunctionValue;
          break;
        case "addTag":
          data.aiAddTagFnValue = this.popupEditFunctionValue;
          break;
      }

      this.$vs.loading();

      try {
        const res = await this.$http.post(
          "/p/chat/assistant/chat/" + this.$route.params.chatbot_id + "/config",
          data
        );

        this.currentChatbot = res.data.data;

        this.popupEditFunction = false;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError") + " - " + e.response.data.message,
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "danger",
          position: "top-right",
        });
      }

      this.$vs.loading.close();
    },
    async fetchChatbot() {
      const res = await axios.get(`/p/chat/${this.$route.params.chatbot_id}`);

      this.iframeKey++;
      this.currentChatbot = res.data.data;

      this.intentions = this.currentChatbot.intentions;
      this.keyEntities = this.currentChatbot.keyEntities;
    },
    async listFiles() {
      try {
        const response = await this.$http.get(
          "/p/chat/ai/assistant/files?acc=" +
            this.$store.state.acc.current_acc.id +
            "&chat=" +
            this.$route.params.chatbot_id
        );
        await this.$store.dispatch("acc/setAiFiles", response.data.data);
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError") + " - " + e.response.data.message,
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    handleFilesBeforeUpload(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      files.forEach((file) => this.filesToUpload.push(file));
    },
    removeFile(index) {
      let arrayFile = Array.from(this.filesToUpload);
      arrayFile.splice(index, 1);

      this.filesToUpload = arrayFile;
    },
    removeSite(index) {
      let arraySite = Array.from(this.sitesToUpload);
      arraySite.splice(index, 1);

      this.sitesToUpload = arraySite;
    },
    async handleUpload() {
      this.popupUpload = false;
      this.$vs.loading();

      let fd = new FormData();

      if (this.typeUpload === "file") {
        this.filesToUpload.forEach((item, i) => {
          fd.append("files" + i, item);
        });
      } else {
        this.sitesToUpload.forEach((item, i) => {
          fd.append("url" + i, item);
        });
      }

      try {
        const res = await this.$http.post(
          "/p/chat/ai/assistant/files/save?",
          fd,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.auth.accessToken,
              "Content-Type": "multipart/form-data",
            },
            params: {
              acc: this.$store.state.acc.current_acc.id,
              chat: this.$route.params.chatbot_id,
            },
          }
        );

        this.$vs.notify({
          time: 2500,
          title: this.$t("Success"),
          text: "Conteúdo adicionado com sucesso.",
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
          position: "top-right",
        });

        this.filesToUpload = [];

        await this.$store.dispatch("acc/setAiFiles", [
          ...this.rowData,
          ...res.data.data,
        ]);
      } catch (e) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: e.response.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      }
      this.$vs.loading.close();
    },
    async getGoals() {
      const response = await this.$http.get("/p/chat/assistant/objective/list");

      if (response.status === 200) {
        this.chatbotGoalOptions = response.data.data;
      }
    },
    async getGoal(id) {
      const response = await this.$http.get(
        "/p/chat/assistant/objective/" + id
      );

      if (response.status === 200) {
        this.goal = response.data.data;

        if (
          this.currentChatbot.objectiveInstruction !== "" &&
          this.currentChatbot.objectiveInstruction !== undefined
        ) {
          this.promptText = this.currentChatbot.objectiveInstruction;
        } else {
          this.promptText = response.data.data.instruction;
        }
      }
    },
    async saveGoalToChat() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      try {
        const responseGoals = await this.$http.post(
          "/p/chat/assistant/objective/save/chat/" +
            this.$route.params.chatbot_id,
          {
            objective: this.goal._id,
            objectiveInstruction: this.promptText,
          }
        );
        const responseSettings = await this.$http.post(
          "/p/chat/assistant/chat/" + this.$route.params.chatbot_id + "/config",
          {
            aiCloseConversationFn: this.closeConversation,
            aiForwardConversationFn: this.humanTransfer,
            aiAddTagFn: this.addTag,
            aiOpenaiModel: this.gptChatModel,
            intentions: this.intentions,
            keyEntities: this.keyEntities,
          }
        );
        if (responseGoals.status === 200 && responseSettings.status === 200) {
          this.$vs.notify({
            title: this.$t("Success"),
            text: "Chatbot configurado com sucesso.",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
            position: "top-right",
            time: 4000,
          });

          localStorage.removeItem("settings");
          localStorage.removeItem("isChatOpen");
          localStorage.removeItem("sessionID");
        }
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
      } catch (err) {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
      }
    },
    openPopupDeleteFile(item) {
      this.fileToDelete = item;
      this.popupDeleteFile = true;
    },
    async deleteFile(id) {
      try {
        this.popupDeleteFile = false;

        const response = await this.$http.delete(
          "/p/chat/ai/assistant/files/delete/" +
            id +
            "?acc=" +
            this.$store.state.acc.current_acc.id +
            "&chat=" +
            this.$route.params.chatbot_id
        );

        await this.$store.dispatch("acc/removeAiFileById", id);

        this.$vs.notify({
          time: 2500,
          title: this.$t("Success"),
          text: response.data.message,
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
          position: "top-right",
        });
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError") + " - " + e.response.data.message,
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
  },
  watch: {
    newIntentionPopup(val) {
      if (val === false) {
        this.newIntention = "";
        this.editIntentionIndex = -1;
      }
    },
    newEntityPopup(val) {
      if (val === false) {
        this.newEntity = "";
        this.editEntityIndex = -1;
      }
    },
    popupEditFunction(val) {
      if (val === false) {
        this.popupEditFunctionTitle = "";
        this.popupEditFunctionValue = "";
        this.popupEditFunctionType = "";
      }
    },
    async chatbotGoal() {
      await this.getGoal(this.chatbotGoal);
    },
  },
};
</script>
